<template>
    <div>
         <el-tabs v-model="activeName" @tab-click="handleClick">

            <el-tab-pane label="销售排行(按售货员)" name="first">

                <rank-table v-if="activeName=='first'" :total="total" :rowList="rowList" @fetchData="fetchData" :tableList="tableList" :activeName="activeName" :typeList="typeList" :shopList="shopList"></rank-table>
                
            </el-tab-pane>

            <el-tab-pane label="销售排行(按会员)" name="second">
                <rank-table v-if="activeName=='second'" :total="total" :rowList="rowList" @fetchData="fetchData" :tableList="tableList" :activeName="activeName" :typeList="typeList" :shopList="shopList"></rank-table>    
            </el-tab-pane>

            <el-tab-pane label="销售排行(按品类)" name="third">
               <rank-table v-if="activeName=='third'" :total="total" :rowList="rowList" @fetchData="fetchData" :tableList="tableList" :activeName="activeName" :typeList="typeList" :shopList="shopList"></rank-table>
            </el-tab-pane>

        </el-tabs>

           <div class="pagina">
                <el-pagination
                        :current-page="page"
                        background
                        @size-change="handleSizeChange"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                        :page-size="$store.state.pageSizes[0]"
                        :page-sizes="$store.state.pageSizes"
                        @current-change="handleCurrentChange"
                    ></el-pagination>
            </div> 
    </div>
</template>

<script>
import rankTable from './components/rankTable.vue'
import LocalPrint from "@/components/localPrint/Index.vue";
import {getTypeList,getMerchantList,SalesRanking} from '@/api/order/offlineOrder/commission'
export default{
    components:{rankTable,LocalPrint},
    created(){
        this.getMerchantList()
        this.getTypeList()
        this.getSalesRanking()
    },
    data(){
        return{
            activeName: 'first',
            typeList:[],
            shopList:[],
            tableList:[],
            rowList:[
                {field_alias:'index',field:'index',field_text:'序号',t_width:100,is_sum:0},
                {field_alias:'hierarchy_name',field:'hierarchy_name',field_text:'门店',t_width:150,is_sum:0},
                {field_alias:'realname',field:'realname',field_text:'售货员',t_width:150,is_sum:0},
                {field_alias:'goods_type_name',field:'goods_type_name',field_text:'品类',t_width:150,is_sum:0},
                {field_alias:'PCS',field:'PCS',field_text:'数量',t_width:150,is_sum:1},
                {field_alias:'Totalweight',field:'Totalweight',field_text:'总件数',t_width:150,is_sum:1},
                {field_alias:'Gold_weigth',field:'Gold_weigth',field_text:'净金重',t_width:150,is_sum:1},
                {field_alias:'Sale_cost',field:'Sale_cost',field_text:'销售工费',t_width:150,is_sum:1},
                {field_alias:'Cost_price',field:'Cost_price',field_text:'成本价',t_width:150,is_sum:1},
                {field_alias:'Sale_price',field:'Sale_price',field_text:'标签价',t_width:150,is_sum:1},
                {field_alias:'discount',field:'discount',field_text:'折扣',t_width:150,is_sum:1},
                {field_alias:'Sale_price_act',field:'Sale_price_act',field_text:'实售价',t_width:150,is_sum:1},
                {field_alias:'Actutal_sale_price',field:'Actutal_sale_price',field_text:'实收金额',t_width:150,is_sum:1},
                {field_alias:'Total_commission_money',field:'Total_commission_money',field_text:'总提成',t_width:150,is_sum:1},
                // {field_alias:'actual_sale_price',field:'actual_sale_price',field_text:'客单价',t_width:150},
                {field_alias:'recycle_price',field:'recycle_price',field_text:'旧料抵扣',t_width:150,is_sum:1},
                {field_alias:'Raw_gold_weight',field:'Raw_gold_weight',field_text:'旧料重',t_width:150,is_sum:1},
                {field_alias:'Raw_sale_cost',field:'Raw_sale_cost',field_text:'旧料工费',t_width:150,is_sum:1},
                {field_alias:'recycle_commission_money',field:'recycle_commission_money',field_text:'旧料提成',t_width:150,is_sum:1},
                {field_alias:'Discount_money',field:'Discount_money',field_text:'售价提成',t_width:150,is_sum:1},
                {field_alias:'Storm_piece_money',field:'Storm_piece_money',field_text:'按件提成',t_width:150,is_sum:1},
                {field_alias:'Gold_gram_money',field:'Gold_gram_money',field_text:'重量提成',t_width:150,is_sum:1},
            ],
            form:{
                start_time:'',
                end_time:'',
                hierarchy_id:'',
                order_num:'',
                goods_number:'',
                goods_type_id:'',
            },
            page:1,
            limit:50,
            type:1,
            total:0,
           
        }
    },
    methods:{

         // 改变分页条数
        handleSizeChange(size) {
            this.limit = size;
            this.getSalesRanking();
        },

        handleCurrentChange(page) {
            this.page = page;
            this.getSalesRanking();
        },


        fetchData(obj){
            console.log(obj)
            this.form=obj.obj
            this.getSalesRanking()
        },

        //获取排行列表
        getSalesRanking(){
            SalesRanking({
                ...this.form,
                page:this.page,
                limit:this.limit,
                type:this.type,
            }).then(res=>{
                if(res.code==1){
                    console.log(res)
                    this.total=res.data.count
                    this.tableList=res.data.list
                }
            })
        },

         //tab切换
         handleClick(tab, event) {
            this.form={}
            if(this.activeName=='first'){
                this.type=1
                this.rowList.splice(1,1,{field_alias:'realname',field:'realname',field_text:'售货员',t_width:150,is_sum:0},)
            }else if(this.activeName=='second'){
                this.type=2
                this.rowList.splice(1,1,{field_alias:'uesrname',field:'uesrname',field_text:'会员',t_width:150,is_sum:0})
            }else if(this.activeName=='third'){
                 this.type=3
                 this.rowList.splice(1,1, {field_alias:'goods_type_name',field:'goods_type_name',field_text:'品类',t_width:150,is_sum:0})
            }
            this.form={
                start_time:'',
                end_time:'',
                hierarchy_id:'',
                order_num:'',
                goods_number:'',
                goods_type_id:'',
            },
            this.getSalesRanking()
         },

         //获取品类列表
        getTypeList(){
            getTypeList().then(res=>{
                if(res.code==1){
                    this.typeList=res.data
                }
            })
        },  

        //获取门店列表
        getMerchantList(){
            getMerchantList().then(res=>{
                if(res.code==1){
                   let shopList= res.data
                   shopList.map(item=>{
                        item.hierarchy_id=Number.parseInt(item.hierarchy_id)
                    })
                    this.shopList=shopList
                }
            })
        },
    }
   
}
</script>

<style lang='less' scoped>

</style>